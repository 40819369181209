import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ArrowOutwardOutlined } from '@mui/icons-material'
import c from 'classnames'

import styles from './Login.module.scss'
import Header from '../../components/Header'
import { setLoading } from '../../store/reducers/data'

const Login = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const navigate = useNavigate()
	const [gallerySlug, setGallerySlug] = React.useState('')
	const [termsAccepted, setTermsAccepted] = React.useState(false)
	const [errorLoadingGallery, toggleErrorLoadingGallery] = React.useState(
		location.search.includes('error')
	)
	const [expiredGallery, toggleExpiredGallery] = React.useState(
		location.search.includes('expired')
	)

	const loginHandler = () => {
		toggleErrorLoadingGallery(false)
		toggleExpiredGallery(false)
		dispatch(setLoading(true))
		navigate(`gallery/${gallerySlug}`)
	}

	return (
		<div className={styles.wrapper}>
			<Header />
			<div className={styles.container}>
				<div className={styles.box}>
					<div className={styles.header}>
						Prijava u sistem za naručivanje fotografija
					</div>
					<div className={styles.content}>
						<div className={styles.terms}>
							<h1>Pravila i uslovi korišćenja</h1>
							<p className={styles.red}>Rok izrade je 14 dana od uplate.</p>
							<p className={styles.red}>Rok za uplatu 5 dana.</p>
							<p className={styles.red}>
								Porudžbina se izrađuje isključivo po uplati.
							</p>
							<p>
								Potrošač ima pravo da odustane od ugovora (porudžbenice)
								zaključenog na daljinu ili izvan poslovnih prostorija u roku od
								1 dana( 24 sata), bez navođenja razloga i bez dodatnih troškova.
							</p>
							<p>
								Lično na adresi Bulevar patrijarha Pavla 5a, na br. tel. 063 82
								97 645 ili na mail adresu fotografns021@gmail.com .
							</p>
							<p>
								Usluga/roba proizvedene prema posebnim zahtevima potrošača ili
								jasno personalizovane nemaju pravo na duži rok odustajanja od
								ugovora (porudžbenice) po zakonu o zaštiti potrošača član 36.
							</p>
							<p>
								Plaćanjem po porudžbenici (specifikacijom i proračunom)
								automatski smatramo da se slažete sa svim navedenim.
							</p>
							<p>Specifikaciju i proračun dobijate na Vaš mail.</p>
							<div
								className={c(styles.checkbox, termsAccepted && styles.checked)}
							>
								<input
									type="checkbox"
									id="terms"
									onChange={(event) => setTermsAccepted(!termsAccepted)}
								/>
								<label htmlFor="terms">
									Pristajem na pravila i uslove korišćenja <span>*</span>
								</label>
							</div>
						</div>
						<div className={styles.inputWrapper}>
							<label htmlFor="gallery">
								Kod galerije <span>*</span>
								<input
									type="text"
									name="gallery"
									onChange={(event) => setGallerySlug(event.target.value)}
									disabled={!termsAccepted}
								/>
							</label>
						</div>
						<div className={styles.buttonWrapper}>
							<button
								type="button"
								onClick={loginHandler}
								disabled={gallerySlug.length === 0 || !termsAccepted}
							>
								Prijavi se <ArrowOutwardOutlined />
							</button>
						</div>
						{errorLoadingGallery && (
							<div className={styles.error}>
								GREŠKA - Galerija sa unetim kodom ne postoji. Proverite da li je
								kod koji unosite tačan.
							</div>
						)}
						{expiredGallery && (
							<div className={styles.error}>
								Vreme za naručivanje fotografija je isteklo.
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login
